import { Formik } from "formik";
import withSession from "../lib/session/session";
import * as Yup from "yup";
import axios from "axios";
import { useState } from "react";
import { useRouter } from "next/dist/client/router";
import { redirectUrlByLocation } from "../lib/helpers/redirects";
import Head from "next/head";
import { getDomain, getPasswordResetLink } from "../lib/helpers/getDomain";
import { captureException } from "@sentry/nextjs";
import { getDefaultDomainConfig } from "../lib/constants/defaultDomainConfig";

export default function Login({ domain, passwordForgotLink }) {
  const [error, setError] = useState("");
  const router = useRouter();

  return (
    <>
      <Head>
        <title>FleetDirect - Inloggen</title>
      </Head>
      <div className="login__container">
        <div className="login__logo">
          <img
            src={
              domain === "ald" || domain === "vermeulen" || domain === "koopman"
                ? `/${domain}/login-logo.png`
                : `/${domain}/login-logo.svg`
            }
            alt={domain + " logo"}
          />
        </div>
        <main role="main">
          <Formik
            initialValues={{ email: "", password: "" }}
            validationSchema={Yup.object({
              email: Yup.string().email("Ongeldig e-mailadres"),
              password: Yup.string().min(
                5,
                "Wachtwoord moet minimaal 5 karakters zijn"
              ),
            })}
            onSubmit={(values, { setSubmitting, setErrors }) => {
              axios
                .post("/api/auth/login", values)
                .then(async () =>
                  axios
                    .get("/api/auth/profile")
                    .then((response) => {
                      const { categories } = response.data;
                      router.push(`${redirectUrlByLocation(categories)}`);
                    })
                    .catch((error) => {
                      captureException(error);
                      error.response?.data?.msg
                        ? setError(error.response.data.msg)
                        : setError("Er is iets mis gegeaan");
                    })
                )
                .catch((error) => {
                  captureException(error);
                  const { msg } = error?.response?.data;
                  if (msg.email || msg.password) setErrors(msg);
                  else setError(error?.response?.data?.msg);
                })
                .then(() => setSubmitting(false));
            }}
          >
            {(formik) => (
              <form
                onSubmit={formik.handleSubmit}
                className="login__form on-submit"
              >
                <p className="subtitle">
                  Voer je logingegevens in om verder te gaan.
                </p>
                {error && <div className="notification is-danger">{error}</div>}
                <div className="field has-margin-top-1">
                  <label htmlFor="login-form-email" className="label">
                    E-mail adres
                  </label>
                  <div className="control has-icons-right">
                    <input
                      type="text"
                      name="email"
                      placeholder="E-mail adres"
                      className="input"
                      autoComplete="off"
                      autoFocus="autofocus"
                      {...formik.getFieldProps("email")}
                    />
                    <span className="icon is-right pr-5">
                      <i className="fal fa-envelope"></i>
                    </span>
                  </div>
                  {formik.errors.email && formik.touched.email && (
                    <div className="notification is-danger">
                      {formik.errors.email}
                    </div>
                  )}
                </div>
                <div className="field">
                  <label htmlFor="login-form-password" className="label">
                    Wachtwoord
                  </label>
                  <div className="control has-icons-right">
                    <input
                      name="password"
                      type="password"
                      placeholder="Wachtwoord"
                      className="input"
                      autoComplete="off"
                      {...formik.getFieldProps("password")}
                    />
                    <span className="icon is-right pr-5">
                      <i className="fal fa-lock-alt"></i>
                    </span>
                  </div>
                  {formik.errors.password && formik.touched.password && (
                    <div className="notification is-danger">
                      {formik.errors.password}
                    </div>
                  )}
                </div>
                <div className="column">
                  <button
                    type="submit"
                    className={`button is-primary ${
                      formik.isSubmitting && "is-loading"
                    } is-medium`}
                  >
                    Inloggen
                  </button>
                </div>
                <div className="column">
                  <a href={passwordForgotLink} className="is-medium is-text">
                    <strong>Wachtwoord vergeten</strong>
                  </a>
                </div>
              </form>
            )}
          </Formik>
        </main>
      </div>
    </>
  );
}

export const getServerSideProps = withSession(async function ({ req }) {
  const domainConfig = req.session.get("domainConfig");
  if (domainConfig && domainConfig.callbackUrl && domainConfig.token) {
    return {
      redirect: {
        destination: domainConfig.callbackUrl,
        permanent: false,
      },
    };
  } else {
    const defaultDomainConfig = getDefaultDomainConfig(req);

    req.session.set("domainConfig", defaultDomainConfig);
    await req.session.save();

    return {
      props: {
        domain: getDomain(req),
        passwordForgotLink: getPasswordResetLink(req),
      },
    };
  }
});
